import React, {Suspense} from 'react';
import { Route, Redirect } from 'react-router-dom';
import LoginService from '../../services/LoginService';
import CONSTANTS from '../../constants';
import { ContextLayout } from '../../utility/context/Layout';
import SpinnerComponent from '../@vuexy/spinner/Fallback-spinner';

const PrivateRoute = ({ component: Component, fullLayout, ...rest }) => (
  <Route {...rest} render={
    (props) => {
      if(LoginService.getToken()) {
        return (
          <ContextLayout.Consumer>
            {context => {
              let LayoutTag =
                fullLayout === true
                  ? context.fullLayout
                  : context.state.activeLayout === 'horizontal'
                  ? context.horizontalLayout
                  : context.VerticalLayout;
              return (
                <LayoutTag {...props} pageTitle={rest.pageTitle} goBackURL={rest.goBackURL}>
                  <Suspense fallback={<SpinnerComponent />}>
                    <Component {...props} />
                  </Suspense>
                </LayoutTag>
              )
            }}
          </ContextLayout.Consumer>
        );
      }
      return <Redirect to={{ pathname: `${CONSTANTS.BASE_URL}login`, state: { from: props.location.pathname, data: props.location.state } }} />;
    }
  } />
);

export default PrivateRoute;