import LoginService from './LoginService';
import CONSTANTS from '../constants';

export default {
  getProducts,
  createProduct
};

async function getProducts() {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PRODUCT.ALL}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not fetch products');
}

async function createProduct(product) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PRODUCT.CREATE}`, {
    method: 'POST',
    body: product,
    headers: { 'Accept': 'application/json', 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not create product');
}
