import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {BrowserRouter, Switch} from "react-router-dom";
import { store } from './redux/storeConfig/store';

import {Layout} from "./utility/context/Layout";
import PublicRoute from "./components/shared/PublicRoute";
import reportWebVitals from './reportWebVitals';
import { Planogram } from "./containers/planogram/planogram";
import PrivateRoute from "./components/helpers/PrivateRoute";

import CONSTANTS from "./constants";
import Login from "./containers/sessions/Login";
import Logout from "./containers/sessions/Logout";
import { Categories } from "./containers/categories/categories";

import { PlanogramTemplates } from "./containers/planogram-templates/planogramTemplates";
import { Products } from "./containers/products/prodcuts";

import 'bootstrap/dist/css/bootstrap.css';
import "./components/@vuexy/rippleButton/RippleButton";
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import './index.scss';

ReactDOM.render(
  <Provider store={ store }>
    <Layout>
      <BrowserRouter>
        <div className='app-container'>
          <Switch>
            <PublicRoute fullLayout exact path={`${CONSTANTS.BASE_URL}login`} pageTitle={'Login'} component={ Login } />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}logout`} pageTitle='Logout' component={ Logout } />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}`} pageTitle='Planograms' component={ PlanogramTemplates } />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}categories`} pageTitle='Categories' component={ Categories } />
            <PrivateRoute fullLayout exact path={`${CONSTANTS.BASE_URL}planograms/edit/:id`} pageTitle='Planogram' component={ Planogram } />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}products`} pageTitle='Rack Images' component={ Products } />
          </Switch>
        </div>
      </BrowserRouter>
    </Layout>
  </Provider>
  , document.getElementById('root'));


reportWebVitals();
