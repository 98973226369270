import moment from 'moment';
import CONSTANTS from '../../constants';

export default class TimeHelper {
  static toFormattedDate(date, format=CONSTANTS.DATES.FORMAT.DISPLAY) {
    if(!date) return '';
    return moment(date).format(format);
  }

  static toFormattedTime(dateTime) {
    if(!dateTime) return '';
    return moment(dateTime).format(CONSTANTS.TIMES.FORMAT.DISPLAY);
  }

  static toFormattedDateTime(dateTime) {
    if(!dateTime) return '';
    return moment(dateTime).format(`${CONSTANTS.DATE_TIMES.FORMAT.DISPLAY}`)
  }

  static getLocalDateTimeFromUTCString(dateTime) {
    return moment.utc(dateTime, CONSTANTS.DATE_TIMES.FORMAT.DB).local();
  }

  static getCurrentDate() {
    return moment().format(CONSTANTS.DATES.FORMAT.DB_DATE);
  }

}
