import { combineReducers } from 'redux'
import { products } from './ProductsReducer';
import { product } from './ProductReducer';


const productsReducer = combineReducers({
  products,
  product,
});

export default productsReducer;
