import CONSTANTS from '../../constants';
import ProductService from '../../services/ProductService';

export function getAllProducts(initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PRODUCT.PRODUCT_ALL,  data: null, loading: true });
      let products = await ProductService.getProducts();
      dispatch({type: CONSTANTS.ACTIONS.PRODUCT.PRODUCT_ALL, data: products, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PRODUCT.PRODUCT_ALL,  data: null, loading: false });
    }
  };
}

export function createProduct(product, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PRODUCT.PRODUCT_CREATE, data: null, loading: true });
      let productCreated = await ProductService.createProduct(product);
      dispatch({type: CONSTANTS.ACTIONS.PRODUCT.PRODUCT_CREATE, data: productCreated, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PRODUCT.PRODUCT_CREATE,  data: null, loading: false });
    }
  };
}