import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import 'react-data-table-component-extensions/dist/index.css';

const DataTableComponent = ({title, data, columns, addNew}) => {

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <Button color='primary' onClick={addNew}>Add New</Button>
      </CardHeader>
      <CardBody>
        <DataTableExtensions
          columns={columns}
          data={data ? data : []}
          print={false}
        >
          <DataTable
            noHeader
            pagination
            paginationRowsPerPageOptions={[25, 50, 100]}
            paginationPerPage={100}
          />
        </DataTableExtensions>
      </CardBody>
    </Card>
  );
}

export default DataTableComponent;
