let ENV = {
  IMAGE_SERVER_URL: process.env.REACT_APP_IMAGE_SERVER_URL,
  API_URL: process.env.REACT_APP_API_URL,
  WEB_URL: process.env.REACT_APP_WEB_URL,
};

let CONSTANTS = {
  API_URL: ENV.API_URL,
  WEB_URL:  ENV.WEB_URL,
  IMAGE_SERVER_URL: ENV.IMAGE_SERVER_URL,
  BASE_URL:  '/',
  APP_TITLE: 'Sunbelt Planogram',
  CACHE_KEY_PREFIX: 'sunbelt_planogram',
  DATES: {
    FORMAT: {
      DISPLAY: 'Do MMM, YYYY',
      DB_DATE: 'YYYY-MM-DD'
    }
  },
  TIMES: {
    FORMAT: {
      DISPLAY: 'hh:mm A'
    }
  },
  DATE_TIMES: {
    FORMAT: {
      DISPLAY: 'DD MMM hh:mm A',
      DB: 'YYYY-MM-DD HH:mm:ss',
    }
  },
  REQUEST_HEADERS: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  URLS: {
    LOGIN: '/auth/login',
    CATEGORY: {
      ALL: '/categories/all',
      CREATE: '/categories/register',
    },
    PRODUCT: {
      ALL: '/products/all',
      CREATE: '/products/register',
    },
    RACKS: {
      BULK_UPDATE: '/racks/bulkUpdate',
    },
    PLANOGRAM: {
      ALL: '/planograms/all',
      CREATE: '/planograms/register',
      GET: (id) => `/planograms/${id}`,
      UPDATE: (id) => `/planograms/register/${id}`,
    }
  },
  ACTIONS: {
    CATEGORY: {
      CATEGORY_ALL: 'GET_ALL_CATEGORIES',
      CATEGORY_CREATE: 'CREATE_CATEGORY',
    },
    PRODUCT: {
      PRODUCT_ALL: 'GET_ALL_PRODUCTS',
      PRODUCT_CREATE: 'CREATE_PRODUCT',
    },
    PLANOGRAM: {
      PLANOGRAM_ALL: 'GET_ALL_PLANOGRAMS',
      PLANOGRAM_CREATE: 'CREATE_PLANOGRAM',
      PLANOGRAM_GET: 'GET_PLANOGRAM',
      PLANOGRAM_UPDATE: 'UPDATE_PLANOGRAM',
    },
    RACKS: {
      RACKS_BULK_CREATE: 'RACKS_BULK_CREATE',
    },
  },
  HTTP_STATUS: {
    OK: 200,
    ERROR: 500,
    UNAUTHORIZED: 401,
  },
  REDUX_ACTIONS: {
    ALERT: {
      SUCCESS: 'ALERT_SUCCESS',
      ERROR: 'ALERT_ERROR',
      CLEAR: 'ALERT_CLEAR'
    },
    USER: {
      REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
      REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
      REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

      LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
      LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
      LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

      LOGOUT: 'USERS_LOGOUT',

      GETALL_REQUEST: 'USERS_GETALL_REQUEST',
      GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
      GETALL_FAILURE: 'USERS_GETALL_FAILURE',

      DELETE_REQUEST: 'USERS_DELETE_REQUEST',
      DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
      DELETE_FAILURE: 'USERS_DELETE_FAILURE'
    }
  }
};

export default CONSTANTS;
