import LoginService from './LoginService';
import CONSTANTS from '../constants';

export default {
  getCategories,
  createCategory
};

async function getCategories(includeProducts = 0) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.CATEGORY.ALL}?includeProducts=${includeProducts}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response && response.responseCode === 200) return response;
  // if(response && response.responseCode === 401) {
  //   LoginService.removeLoginData();
  //   window.location = '/';
  // }
  throw new Error('Could not fetch categories');
}

async function createCategory(category) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.CATEGORY.CREATE}`, {
    method: 'POST',
    body: JSON.stringify(category),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not create category');
}
