import React, {useEffect} from 'react';
import {Product} from "../product/product";

export const ProductList = (props) => {
  let { products, updateImage, selectedImage } = props;

  useEffect(() => {
    selectedImage = props.selectedImage;
  })

  return (
    <div style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column', border: '0.2rem solid black', backgroundColor: '#fff'}}>
      { products && products.length > 0 && products.map( (product) => <Product selectedImage={selectedImage} product={product} updateImage={updateImage} /> ) }
    </div>
  );
}