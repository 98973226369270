import CONSTANTS from '../constants';

export default {
  getToken,
  setLoginData,
  removeLoginData,
  login
};

function setLoginData(tokenData) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`, tokenData.token);
}

function removeLoginData() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
}

function getToken() {
  let token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  if (token) return { token };
  return null;
}

async function login(email, password) {
  let data = { email, password };
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: CONSTANTS.REQUEST_HEADERS
  });
  response = await response.json();
  if(response.responseCode === CONSTANTS.HTTP_STATUS.OK) {
    return { token: response.response.token };
  }
  throw new Error(`Could not login: ${response}`);
}
