import React, {useEffect, useState} from 'react';
import {ClipLoader} from 'react-spinners';

import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const PlanogramTemplateFormModal = (props) => {

  const {
    isOpen,
    submitForm,
    toggleModal,
    item,
  } = props;

  const template = {
    name: '',
    coolers: 0,
    racksPerCooler: []
  }

  useEffect(() => {
    let temp = item ? item : template;
    setPlanogramItem(temp);
  }, [props])

  const [planogramItem, setPlanogramItem] = useState(template);
  const [saving, setSaving] = useState(false);

  const updateItem = (option, val, index = null) => {
    let pi = JSON.parse(JSON.stringify(planogramItem));
    if(option !== 'name')
      val = parseInt(val ? val > 20 ? 20 : val : 0);
    if(index === null) {
      pi[option] = val;
      if (option === 'coolers')
        pi['racksPerCooler'] = Array.apply(null, Array(val)).map( _ => 8 );
    } else {
      pi[option][index] = val;
    }
    setPlanogramItem(pi);
  }

  const saveItem = async () => {
    setSaving(true);
    await submitForm(planogramItem);
    setSaving(false);
    toggleModal();
  }

  return (
    <Modal isOpen={isOpen}
           toggle={() => toggleModal()} >
      <ModalHeader>
        <span>New Planogram</span>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Name:</Label>
          <Input type='text' value={planogramItem.name} onChange={(e) => updateItem('name', e.target.value)} placeholder='Name' />
        </FormGroup>
        <FormGroup>
          <Label>Coolers:</Label>
          <Input type='text' value={planogramItem.coolers} onChange={(e) => updateItem('coolers', e.target.value)} placeholder='Coolers' readOnly={ item ? true : false } />
        </FormGroup>
        <FormGroup>
          <Label>Racks Per Cooler:</Label>
          <br />
          { planogramItem.racksPerCooler.map( (val, index) => {
            return (
              <>
                <Label>{index+1}: </Label>
                <Input type='text' value={val} onChange={(e) => updateItem('racksPerCooler', e.target.value, index)} placeholder='Racks' />
              </>
          );
          } ) }
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={ saveItem } disabled={saving} color='primary'>
          { saving ? <ClipLoader size={14} /> : 'Save' }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PlanogramTemplateFormModal