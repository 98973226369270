import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Table, Container, Button, CustomInput, Input} from 'reactstrap';
import Select from 'react-select';
import './products.scss';
import {createProduct, getAllProducts} from "../../redux/actions/ProductActions";
import {getAllCategories} from "../../redux/actions/CategoryActions";
import CONSTANTS from "../../constants";

export const Products = () => {

  const dispatch = useDispatch();
  const categoriesProp = useSelector((state) => state.categories.categories.data);
  const products = useSelector((state) => state.products.products.data);
  const [category, setCategory] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [racks, setRacks] = useState(1);
  const [categories, setCatgeories] = useState([]);

  const saveProduct = async () => {
    console.log("saving product with image");
    const fileInput = document.querySelector("#fileInput");
    const formData = new FormData();
    formData.append('file', fileInput.files[0]);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('racks', racks);
    formData.append('category_id', category);
    await dispatch(createProduct(formData));
    dispatch(getAllProducts());
  }

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    if(categoriesProp) {
      const cats = [];
      for (let i = 0; i < categoriesProp.length; i++) {
        cats.push({
          value: categoriesProp[i].id,
          label: categoriesProp[i].name,
        });
      }
      setCatgeories(cats);
    }
  }, [categoriesProp])

  return (
    <Table className="table-hover-animation" responsive>
      <thead>
      <tr>
        <th></th>
        <th>Name</th>
        <th>Racks</th>
        <th>Category</th>
        <th>Image</th>
        {/*<th>Action</th>*/}
      </tr>
      </thead>
      <tbody>
      {/*<tr>*/}
      {/*  <td>0</td>*/}
      {/*  <td>*/}
      {/*    <Input type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' />*/}
      {/*  </td>*/}
      {/*  <td>*/}
      {/*    <Input type='number' value={racks} onChange={(e) => setRacks(e.target.value)} placeholder='Racks' />*/}
      {/*  </td>*/}
      {/*  <td style={{ width: '200px' }}>*/}
      {/*    <Select options={categories} onChange={e => setCategory(e.value)} />*/}
      {/*  </td>*/}
      {/*  <td>*/}
      {/*    <CustomInput*/}
      {/*      type="file"*/}
      {/*      id="fileInput"*/}
      {/*      name="file"*/}
      {/*    />*/}
      {/*  </td>*/}
      {/*  <td>*/}
      {/*    <Button color='primary' onClick={saveProduct}>Save</Button>*/}
      {/*  </td>*/}
      {/*</tr>*/}
      { products && products.map( (product, index) => {
        return (
          <tr>
            <td>{ index + 1 }</td>
            <td>{product.name}</td>
            <td>{product.racks}</td>
            <td>{product.Category.name}</td>
            <td><img src={`${CONSTANTS.IMAGE_SERVER_URL}/${product.imagePath}`} width='100px' /></td>
            {/*<td></td>*/}
          </tr> );
      }) }
      </tbody>
    </Table>
  ) ;
}