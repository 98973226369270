import { combineReducers } from 'redux'
import { planograms } from './PlanogramsReducer';
import { planogram } from './PlanogramReducer';


const planogramsReducer = combineReducers({
  planograms,
  planogram,
});

export default planogramsReducer;
