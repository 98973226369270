import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ChevronDown, ChevronUp} from "react-feather";

import { Cooler } from "../../components/cooler/cooler";

import './planogram.scss';
import {getAllCategories} from "../../redux/actions/CategoryActions";
import {getPlanogram} from "../../redux/actions/PlanogramActions";
import {Spinner, Collapse, Button} from "reactstrap";
import {ProductList} from "../../components/product-list/product-list";
import CONSTANTS from "../../constants";
import {PanZoom} from "react-easy-panzoom";

export const Planogram = (props) => {

  const { location: {
    pathname
  }} = props;

  const pathParts = pathname.split('/');
  const planId = pathParts[pathParts.length - 1];

  const dispatch = useDispatch();

  const planogram = useSelector((state) => state.planograms.planogram.data);
  const categories = useSelector((state) => state.categories.categories.data);

  const name = planogram ? planogram.name : '';

  useEffect(() => {
    dispatch(getAllCategories(1));
    dispatch(getPlanogram(planId, 1));
  }, []);

  const [selectedImage, setSelectedImage] = useState(null );
  const [currentCat, setCurrentCat] = useState(null );
  const [saving, setSaving] = useState(false );

  const getCoolers = ({ Coolers }) => {
    if(!Coolers)
      return null;

    const coolers = [];
    for( let i=0; i<Coolers.length; i++ ) {
      const shelf = <Cooler cooler={Coolers[i]} selectedImage={ selectedImage } />;
      coolers.push(shelf);
    }
    return coolers;
  }

  const updateImageSelection = (product) => {
    setSelectedImage(product);
  }

  const renderCategories = () => {
    if(categories && categories.length === 0)
      return null;

    const categoryList = [];
    for( let i=0; i<categories.length; i++) {
      const cat =
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '2.5rem', backgroundColor: 'var(--primary)', border: '1px solid black' }} onClick={() => setCurrentCat(categories[i].id === currentCat ? null : categories[i].id)}>
            <span className='ml-1'>{categories[i].name}</span>
            { currentCat === categories[i].id ? <ChevronUp /> : <ChevronDown /> }
          </div>
          <Collapse isOpen={currentCat === categories[i].id}>
            <ProductList products={categories[i].Products} selectedImage={selectedImage} updateImage={updateImageSelection} />
          </Collapse>
        </div>;
      categoryList.push(cat);
    }

    const addTextButton =
      <div>
        <div style={{ textAlign: 'center' }}>
          <Button color='primary' className='my-1' onClick={(e) => {
            setSelectedImage({isTextBox: true, text: 'Store Option'});
          }}>
            Add Text Box
          </Button>
        </div>
      </div>

    categoryList.push(addTextButton);

    return categoryList;
  }

  return (
    <div class='planogram-container'>
      <div class='header display-flex flex-space-between align-items-center mr-1 ml-1'>
        <p style={{ fontSize: '1.5rem' }}>{name}</p>
        { saving ? <Spinner /> : <a className='btn btn-primary' href={`${CONSTANTS.API_URL}/planograms/${planogram ? planogram.id : ''}/pdf`} target='_blank' > Download </a> }
      </div>
      <div class='planogram'>
        <div className='product-container'>
          { categories ? renderCategories() : null }
        </div>
        <div className='cooler-container'>
          { planogram && planogram.id === planId ?
          <PanZoom className='full-width full-height'>
            { getCoolers(planogram) }
          </PanZoom> : <Spinner size={30} /> }
        </div>
      </div>
    </div>
  );
}