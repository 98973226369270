import React, {useEffect, useState} from 'react';
import CONSTANTS from "../../constants";
import {Card, CardBody, CardSubtitle, CardTitle} from "reactstrap";
import './product.scss';

export const Product = (props) => {
  const {
    updateImage,
    product: {
      imagePath,
      name,
      racks,
      id
  } } = props;

  let [isSelected, setSelected] = useState(false);

  useEffect(() => {
    const { selectedImage } = props;
    const isSel = selectedImage ? selectedImage.id === id : false;
    setSelected(isSel);
  });

  return (
    <div onClick={() => updateImage(props.product)}>
      <Card className={ isSelected ? 'selected' : 'hello' }>
        <CardBody>
          <CardTitle tag="h5">{name}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">Racks: {racks}</CardSubtitle>
        </CardBody>
        <img src={`${CONSTANTS.IMAGE_SERVER_URL}/${imagePath}`} width={'100%'} style={{objectFit: 'contain'}} />
      </Card>
    </div>
  );
}