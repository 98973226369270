import React from 'react';
import { Navbar, NavItem, NavLink, Row } from 'reactstrap';
import classnames from 'classnames';
import CONSTANTS from '../../../constants';
import * as Icon from 'react-feather';

const ThemeNavbar = props => {
  const colorsArr = [ 'primary', 'danger', 'success', 'info', 'warning', 'dark'];
  const navbarTypes = ['floating' , 'static' , 'sticky' , 'hidden'];
  let { sidebarVisibility } = props;
  return (
    <React.Fragment>
      <div className='content-overlay' />
      <div className='header-navbar-shadow' />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light': props.navbarColor === 'default' || !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden' && !props.horizontal,
            'floating-nav':
              (props.navbarType === 'floating' && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            'navbar-static-top':
              props.navbarType === 'static' && !props.horizontal,
            'fixed-top': props.navbarType === 'sticky' || props.horizontal,
            'scrolling': props.horizontal && props.scrolling

          }
        )}
      >
        <div className='navbar-wrapper'>
          <div className='navbar-container content'>
            <div
              className='navbar-collapse d-flex justify-content-between align-items-center'
              id='navbar-mobile'
            >
              {props.horizontal ? (
                <div className='logo d-flex align-items-center'>
                  <div className='brand-logo mr-50' />
                  <h2 className='text-primary brand-text mb-0'>Vuexy</h2>
                </div>
              ) : null}
              <Row>
                <div className='mr-auto float-left bookmark-wrapper d-flex align-items-center'>
                  <ul className='navbar-nav'>
                    { props.goBackURL ?
                    <NavItem className='mobile-menu mr-auto'>
                      <NavLink
                        className='nav-menu-main menu-toggle hidden-xs is-active'
                        onClick={ _ => props.goBackURL === 'home' ? props.history.goBack() : props.history.push(`${CONSTANTS.BASE_URL}${props.goBackURL}`)}
                      >
                        <Icon.ArrowLeft className='ficon' />
                      </NavLink>
                    </NavItem> :
                    <NavItem className='d-xl-none mobile-menu mr-auto'>
                      <NavLink
                        className='nav-menu-main menu-toggle hidden-xs is-active'
                        onClick={sidebarVisibility}
                      >
                        <Icon.Menu className='ficon' />
                      </NavLink>
                    </NavItem> }
                  </ul>
                  <span className='pl-1 text-primary brand-text mb-0 font-medium-5'>{props.pageTitle}</span>
                </div>
              </Row>
              <Row />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
};


export default ThemeNavbar;
