import React, {useEffect, useState} from 'react';
import './rack.scss';
import CONSTANTS from "../../constants";

const getBackgroundStyle = (url) => {
  return `url(${encodeURI(url)})`;
}

export const Rack = (props) => {
  const {
    rack: {
      size = 1,
      border = true,
      product,
      rack_text
    },
    index = 0
  } = props;
  let image = null;
  if(product) {
    image = product.imagePath;
  }

  let rackRef = React.createRef();
  let [currentHeight, setCurrentHeight] = useState(200);

  useEffect(() => {
    setCurrentHeight((rackRef.current.clientHeight/size) * 2);
  }, [rackRef])

  return (
    <div ref={rackRef} className='rack' data-index={index} style={{ flex: `${size} 1 0`, display: 'flex', width: currentHeight }}>
      { image ?
      <div style={{flex: 1, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', backgroundImage: getBackgroundStyle(`${CONSTANTS.IMAGE_SERVER_URL}/${image}` ) }}>
      </div>
        : rack_text ? <input onChange={ (e) => {
                                props.updateText(e.target.value, index);
                              }}
                             onBlur={props.updateData}
                             type='text' value={rack_text} className='align-self-center m-auto p-50 border border-dark border-2 small width-100' /> : 'Rack' }
    </div>
  );
}