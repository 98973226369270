import LoginService from './LoginService';
import CONSTANTS from '../constants';

export default {
  bulkUpdateRacks
};

async function bulkUpdateRacks(racks) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.RACKS.BULK_UPDATE}`, {
    method: 'POST',
    body: JSON.stringify({racks}),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not create product');
}
