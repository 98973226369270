import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import categories from './categories';
import products from './products';
import planograms from './planograms';
import customizer from './customizer';

const rootReducer = combineReducers({
  categories,
  planograms,
  products,
  customizer,
  form
});

export default rootReducer
