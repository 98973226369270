import React, { useState } from 'react';
import { Rack } from '../rack/rack';
import RackService from "../../services/RackService";
import './cooler.scss';

export const Cooler = (props) => {
  const { cooler, selectedImage } = props;

  const imageSize = selectedImage ? selectedImage.racks : 1;
  const imagePath = selectedImage ? selectedImage.imagePath : '';
  const product_id = selectedImage ? selectedImage.id : '';

  const [racksData, setRacksData] = useState(JSON.parse(JSON.stringify(cooler.Racks)));

  const updateTextOfRack = (text, index) => {
    const newRacksData = JSON.parse(JSON.stringify(racksData));
    newRacksData[index].rack_text = text;
    setRacksData(newRacksData);
  }

  const bulkUpdateData = () => {
    RackService.bulkUpdateRacks(racksData);
  }

  const getRacks = () => {
    const racks = [];
    for( let i=0; i<racksData.length; i++ ) {
      if(racksData[i].size > 0)
        racks.push(<Rack rack={racksData[i]} index={i} updateText={updateTextOfRack.bind(this)} updateData={bulkUpdateData.bind(this)} />);
    }
    return racks;
  }

  const rackSelected = (e) => {
    if(!selectedImage)
      return;

    let { target } = e;
    let index = 0;
    if(target.dataset.index)
      index = target.dataset.index;
    else
      index = target.parentElement.dataset.index;
    index = parseInt(index);

    if(!selectedImage.isTextBox) {
      if (index + imageSize <= racksData.length) {
        const newRacksData = JSON.parse(JSON.stringify(racksData));
        newRacksData[index].size = imageSize;
        newRacksData[index].image = imagePath;
        newRacksData[index].product_id = product_id;
        newRacksData[index].product = selectedImage;
        newRacksData[index].rack_text = null;
        for (let i = index + 1; i < index + imageSize; i++)
          newRacksData[i].size = 0;
        let i = index + imageSize;
        while (i < newRacksData.length && newRacksData[i].size === 0) {
          newRacksData[i].size = 1;
          i++;
        }
        setRacksData(newRacksData);
        RackService.bulkUpdateRacks(newRacksData);
      }
    } else if(selectedImage.isTextBox && !racksData[index].rack_text) {
      const newRacksData = JSON.parse(JSON.stringify(racksData));
      newRacksData[index].rack_text = selectedImage.text;
      newRacksData[index].product_id = null;
      newRacksData[index].product = null;
      newRacksData[index].size = 1;
      let i = index+1;
      while(i < newRacksData.length && newRacksData[i].size === 0) {
        newRacksData[i].size = 1;
        i++;
      }

      setRacksData(newRacksData);
      RackService.bulkUpdateRacks(newRacksData);
    }
  }

  return (
    <div className='cooler-wrapper'>
      <div className='cooler' onClick={rackSelected.bind(this)}>
        { getRacks() }
      </div>
    </div>
  );
}