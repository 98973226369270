import CONSTANTS from '../../constants';
import CategoryService from '../../services/CategoryService';

export function getAllCategories(includeProdcuts = 0, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.CATEGORY.CATEGORY_ALL,  data: null, loading: true });
      let categories = await CategoryService.getCategories(includeProdcuts);
      dispatch({type: CONSTANTS.ACTIONS.CATEGORY.CATEGORY_ALL, data: categories, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.CATEGORY.CATEGORY_ALL,  data: null, loading: false });
    }
  };
}

export function createCategory(category, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.CATEGORY.CATEGORY_CREATE, data: null, loading: true });
      let categoryCreated = await CategoryService.createCategory(category);
      dispatch({type: CONSTANTS.ACTIONS.CATEGORY.CATEGORY_CREATE, data: categoryCreated, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.CATEGORY.CATEGORY_CREATE,  data: null, loading: false });
    }
  };
}