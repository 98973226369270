import CONSTANTS from '../../constants';
import PlanogramService from '../../services/PlanogramService';

export function getAllPlanograms(initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_ALL,  data: null, loading: true });
      let planorgams = await PlanogramService.getPlanograms();
      dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_ALL, data: planorgams, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_ALL,  data: null, loading: false });
    }
  };
}

export function getPlanogram(planogramId, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_GET,  data: null, loading: true });
      let planorgams = await PlanogramService.getPlanogram(planogramId);
      dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_GET, data: planorgams, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_GET,  data: null, loading: false });
    }
  };
}

export function createPlanogram(planogram, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_GET, data: null, loading: true });
      let planogramCreated = await PlanogramService.createPlanogram(planogram);
      dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_GET, data: planogramCreated, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_GET,  data: null, loading: false });
    }
  };
}

export function updatePlanogram(planogram, initialize= false) {
  return async dispatch => {
    try {
      if(initialize) dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_UPDATE, data: null, loading: true });
      let planogramCreated = await PlanogramService.updatePlanogram(planogram);
      dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_UPDATE, data: planogramCreated, loading: false });
    }
    catch (error) {
      console.log(error);
      dispatch({type: CONSTANTS.ACTIONS.PLANOGRAM.PLANOGRAM_GET,  data: null, loading: false });
    }
  };
}