import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Table, Button, Input} from 'reactstrap';

import {createCategory, getAllCategories} from "../../redux/actions/CategoryActions";

import './categories.scss';
import TimeHelper from "../../utility/view/TimeHelper";

export const Categories = () => {

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories.data);
  const [name, setName] = useState('');

  const saveCategory = async () => {
    await dispatch(createCategory({ name }));
    setName('');
    dispatch(getAllCategories());
  }

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  return (
    <Table className="table-hover-animation" responsive>
      <thead>
        <tr>
          <th></th>
          <th>NAME</th>
          <th>Date Added</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            0
          </td>
          <td>
            <Input type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Category name' />
          </td>
          <td>
            <Button color='primary' onClick={saveCategory}>Save</Button>
          </td>
        </tr>
        { categories && categories.map( (category, index) => {
          return (
            <tr>
            <td>{index + 1}</td>
            <td>{category.name}</td>
            <td>{TimeHelper.toFormattedDateTime(category.created_at)}</td>
          </tr> );
        }) }
      </tbody>
    </Table>
  );
}