import { combineReducers } from 'redux'
import { categories } from './CategoriesReducer';
import { category } from './CategoryReducer';


const categoriesReducer = combineReducers({
  categories,
  category,
});

export default categoriesReducer;
