import LoginService from './LoginService';
import CONSTANTS from '../constants';

export default {
  getPlanograms,
  createPlanogram,
  getPlanogram,
  updatePlanogram
};

async function getPlanograms() {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PLANOGRAM.ALL}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not fetch products');
}

async function getPlanogram(id) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PLANOGRAM.GET(id)}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not fetch products');
}

async function createPlanogram(planogram) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PLANOGRAM.CREATE}`, {
    method: 'POST',
    body: JSON.stringify(planogram),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not create product');
}

async function updatePlanogram(planogram) {
  let { token } = LoginService.getToken();
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.PLANOGRAM.UPDATE(planogram.id)}`, {
    method: 'POST',
    body: JSON.stringify(planogram),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'Authorization': `bearer ${token}` }
  });
  response = await response.json();
  if(response) return response;

  throw new Error('Could not create product');
}
