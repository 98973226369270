import React from 'react';
import * as Icon from 'react-feather';
const navigationConfig = [
  {
    id: "planograms",
    title: "Planograms",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "categories",
    title: "Categories",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/categories"
  },
  {
    id: "products",
    title: "Products",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/products"
  },
]

export default navigationConfig
