import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Table, Container, Button, Input} from 'reactstrap';

import './planogramTemplates.scss';
import {createPlanogram, getAllPlanograms, updatePlanogram} from "../../redux/actions/PlanogramActions";
import PlanogramTemplateFormModal from "../../components/shared/forms/PlanogramTemplateFormModal";
import DataTableComponent from "../../components/shared/DataTableComponent";
import TimeHelper from "../../utility/view/TimeHelper";

export const PlanogramTemplates = (props) => {

  const dispatch = useDispatch();
  const planograms = useSelector((state) => state.planograms.planograms.data);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const savePlanogram = async (template) => {

    if( template.id )
      await dispatch(updatePlanogram (template));
    else
      await dispatch(createPlanogram (template));

    dispatch(getAllPlanograms());
  }

  const viewPlanogram = async (planogram) => {
    props.history.push(`/planograms/edit/${planogram.id}`);
  }

  useEffect(() => {
    dispatch(getAllPlanograms());
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const editPlanogram = (planogram) => {
    let planCopy = {
      id: planogram.id,
      name: planogram.name,
      coolers: planogram.totalCoolers,
      racksPerCooler: planogram.Coolers.map(c => c.Racks.length),
    }
    setSelectedItem(planCopy);
    toggleModal();
  }

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true
    }, {
      name: 'Total Coolers',
      selector: 'totalCoolers',
      sortable: false
    }, {
      name: 'Created At',
      selector: 'created_at',
      sortable: true,
      format: ({created_at}) => {
          return TimeHelper.toFormattedDate(created_at);
      },
    }, {
      name: 'Actions',
      selection: 'actions',
      sortable: false,
      cell: row => (
        <>
          <Button color='primary' onClick={() => {
            viewPlanogram(row);
          }}>View</Button>
          <Button color='primary' onClick={() => {
            editPlanogram(row);
          }}>Edit</Button>
        </>
      )
    }];

  return (
    <>
      <DataTableComponent addNew={ toggleModal.bind(this) } columns={columns} title={'Planograms'} data={planograms} />
      <PlanogramTemplateFormModal item={selectedItem} isOpen={showModal} toggleModal={toggleModal.bind(this)} submitForm={ savePlanogram.bind(this) } />
    </>
  );
}